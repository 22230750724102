export default [
    {
        name: 'HotelGroupList',
        path: 'hotels/groups',
        component: () => import('./groups/HotelGroupList.vue'),
        meta: {
            title: 'Hotels Groups',
            perms: 'hotels',
            icon: 'fa-solid fa-layer-group',
        },
    },
    {
        name: 'HotelsBookedByCity',
        path: 'hotels/booked_by_city',
        component: () => import('./utilities/HotelsBookedByCity.vue'),
        meta: {
            title: 'Hotels Booked By City',
            perms: 'hotels',
            icon: 'fa-solid fa-hotel',
        },
    },

    {
        name: 'HotelCreate',
        path: 'hotels/create',
        component: () => import('./views/HotelForm.vue'),
    },
    {
        name: 'HotelUpdate',
        path: 'hotels/:hotel',
        component: () => import('./views/HotelForm.vue'),
    },

    {
        name: 'HotelList',
        path: 'hotels',
        component: () => import('./views/HotelList.vue'),
        meta: {
            title: 'Hotels',
            perms: 'hotels',
            icon: 'fa-solid fa-hotel',
        },
    },
]
