<template>
    <div class="field">
        <label
            v-if="label"
            class="label is-small"
        >
            {{ label }}
        </label>
        <div class="field has-addons">
            <p
                v-for="[pk, lbl, icon] in availableChoices"
                :key="pk"
                class="control"
            >
                <button
                    class="button is-small"
                    :class="{
                        'is-primary': modelValue.includes(pk),
                    }"
                    @click="onButtonClicked(pk)"
                >
                    <span
                        v-if="icon"
                        class="icon is-small"
                    >
                        <i :class="icon"></i>
                    </span>
                    <span>{{ lbl }}</span>
                </button>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    name: 'T3MultipleToggleField',
    props: {
        modelValue: {
            type: Array,
            default: () => [],
        },
        label: {
            type: String,
            default: undefined,
        },
        choices: {
            type: [String, Array],
            required: true,
        },
        noEmpty: {
            type: Boolean,
            required: false,
        },
    },
    emits: ['update:model-value'],
    computed: {
        availableChoices() {
            if (Array.isArray(this.choices)) {
                return this.choices
            } else if (typeof this.choices === 'string') {
                return this.choicesList(this.choices)
            }
            return null
        },
    },
    methods: {
        onButtonClicked(pk) {
            if (this.modelValue.includes(pk)) {
                this.$emit(
                    'update:model-value',
                    this.modelValue.filter((v) => v !== pk)
                )
            } else {
                this.$emit('update:model-value', [...this.modelValue, pk])
            }
        },
    },
}
</script>
