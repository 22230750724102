<template>
    <div class="file is-small">
        <label class="file-label">
            <input
                ref="input"
                class="file-input"
                type="file"
                name="resume"
                :disabled="readonly || undefined"
                :accept="accept"
                @change="onFileChange"
            />
            <span class="file-cta">
                <span class="file-icon">
                    <i :class="icon"></i>
                </span>
                <span class="file-label">{{ label }}</span>
            </span>
        </label>
    </div>
</template>

<script>
export default {
    name: 'T3UploadButton',
    props: {
        endpoint: {
            type: String,
            required: true,
        },
        label: {
            type: String,
            default: 'Upload',
        },
        icon: {
            type: String,
            default: 'fa-solid fa-upload',
        },
        form: {
            type: Object,
            default: () => ({}),
        },
        accept: {
            type: String,
            default: undefined,
        },
        readonly: {
            type: Boolean,
            default: false,
        },
    },
    emits: ['uploaded'],
    methods: {
        async onFileChange(event) {
            console.log(event)
            const file = event.target.files[0]
            const response = await this.$rest.upload(this.endpoint, file)
            this.$emit('uploaded', response)
            this.$refs.input.value = ''
        },
    },
}
</script>
